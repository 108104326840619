<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.movement") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            >
            </el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            >
            </el-date-picker>
          </div>
        </div>
        <div
            class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}</el-button
          >
          <export-excel
            class="btn excel_btn mr-2"
            :data="list"
            worksheet="Пользователи"
            name="Пользователи.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete"
              >Excel</el-button
            >
          </export-excel>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}
                </el-checkbox>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.mainwarehouse_id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.warehouse_id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.created_at" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.user_id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.wlec"></crm-th>
            <crm-th :column="columns.settings"></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
            >
            </el-input>
          </th>
          <th v-if="columns.mainwarehouse_id.show">
            <el-select
                clearable
                size="mini"
                v-model="filterForm.mainwarehouse_id"
                :placeholder="columns.mainwarehouse_id.title"
            >
              <el-option
                  v-for="item in warehouses"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.warehouse_id.show">
            <el-select
                clearable
                size="mini"
                v-model="filterForm.warehouse_id"
                :placeholder="columns.warehouse_id.title"
            >
              <el-option
                  v-for="item in warehouses"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
                :placeholder="columns.created_at.title"
                v-model="filterForm.created_at"
                size="mini"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.user_id.show">
            <el-select
                clearable
                size="mini"
                v-model="filterForm.user_id"
                :placeholder="columns.user_id.title"
            >
              <el-option
                  v-for="item in users"
                  :key="item.id"
                  :label="(item.surname || '') + ' ' + (item.name || '')"
                  :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.wlec.show"></th>

          <th v-if="columns.settings.show"></th>
        </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="moving in list" :key="moving.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ moving.id }}</td>
            <td v-if="columns.mainwarehouse_id.show">
              {{ moving.mainwarehouse ? moving.mainwarehouse.name : "" }}
            </td>
            <td v-if="columns.warehouse_id.show">
              {{ moving.warehouse ? moving.warehouse.name : "" }}
            </td>
            <td v-if="columns.created_at.show">{{ moving.created_at }}</td>
            <td v-if="columns.user_id.show">
              {{ moving.user ? moving.user.name : "" }}
            </td>
            <td v-if="columns.wlec.show">
              <div @click="showItems(moving)" class="vuee">
                {{ $t("message.showing") }}
              </div>
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="moving"
                name="movings"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              >
              </crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="85%"
      :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <create-form
        :drawer-name="drawer.create.name"
        :ref="drawer.create.component"
      />
    </el-drawer>

    <el-drawer
      size="85%"
      :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :with-header="false"
      :ref="drawer.update.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <update-form
        :drawer-name="drawer.update.name"
        :ref="drawer.update.component"
        :moving="selectedItem"
      />
    </el-drawer>

    <el-dialog
      :title="dialog_title"
      :visible.sync="isItemsModelVisible"
      width="80%"
      :before-close="onCloseDialog"
    >
      <el-table
        :data="movingProducts"
        v-if="isLoadingItems"
        style="width: 100%"
        border
      >
        <el-table-column label="#" width="30"
          ><template slot-scope="scope">{{
            scope.$index + 1
          }}</template></el-table-column
        >
        <el-table-column
          prop="product.name"
          :label="$t('message.product')"
        ></el-table-column>
        <el-table-column
          prop="quantity"
          :label="$t('message.quantity')"
        >
          <template slot-scope="scope">
            {{ scope.row.quantity | formatMoney }}
          </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          :label="$t('message.date')"
        ></el-table-column>
        <el-table-column
          prop="updated_at"
          :label="$t('message.update_date')"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-drawer size="50%" :wrapperClosable="false" :visible.sync="tableID2" :with-header="false">
      <CrmLook />
    </el-drawer>
  </div>
</template>
<script>
import CreateForm from "./components/CreateForm";
import UpdateForm from "./components/UpdateForm";
import CrmLook from "./components/crm-look";
import { i18n } from "@/utils/i18n";

import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import warehouses from "@/components/inventory-select/warehouses";
import CrmTh from "@/components/crm/include/crm-th.vue";

export default {
  mixins: [list, drawer],
  name: "moving",
  data() {
    return {
      data_s: "",
      loadingData: false,
      drawer: false,
      tableID2: false,
      tableID: false,
      tableUser: false,
      dialog_title: null,

      isItemsModelVisible: false,
      isLoadingItems: false,
      selectedItem: null,
      drawer: {
        create: {
          name: "create",
          component: "componentCreate",
          status: false,
        },
        update: {
          name: "update",
          component: "componentUpdate",
          status: false,
        },
      },
      actions: ["edit", "delete"],
    };
  },
  components: {
    CrmTh,
    CrmLook,
    CreateForm,
    UpdateForm,
    warehouses,
  },
  computed: {
    ...mapGetters({
      list: "movings/list",
      columns: "movings/columns",
      pagination: "movings/pagination",
      filter: "movings/filter",
      sort: "movings/sort",
      warehouses: "warehouses/list",
      users: "users/list",
    }),
  },
  async mounted() {
    await this.loadWarehouses();
    await this.loadUsers();
  },
  methods: {
    ...mapActions({
      getMovings: "movings/index",
      updateSort: "movings/updateSort",
      updateFilter: "movings/updateFilter",
      updateColumn: "movings/updateColumn",
      updatePagination: "movings/updatePagination",
      showMoving: "movings/show",
      empty: "movings/empty",
      delete: "movings/destroy",
      refresh: "movings/refreshData",
      loadWarehouses: "warehouses/index",
      loadUsers: "users/index",
    }),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getMovings(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    refreshData() {
      this.refresh().then(() => {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
      });
    },
    async edit(model) {
      this.selectedItem = model;
      this.drawer.update.status = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showItems(model) {
      this.isItemsModelVisible = true;
      this.showMoving(model.id)
        .then((res) => {
          const moving = res.data.moving;
          this.movingProducts = moving.movingitems;
          if (moving.requirement !== null) {
            this.dialog_title = i18n.t('message.products') + ' (' + i18n.t(`message.requirement`) + ' #' + moving.requirement.id + ')';
          } else {
            this.dialog_title = i18n.t('message.products');
          }
          this.isLoadingItems = true;
        })
        .catch((err) => {
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    onCloseDialog(done) {
      done();
      this.empty();
      this.isLoadingItems = false;
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    drawerClosed(ref) {
      this.$refs[ref].closed();
    },
    open() {
      this.$confirm(
        i18n.t("message.delete_notification"),
        i18n.t("message.warning"),
        {
          confirmButtonText: i18n.t("message.yes"),
          cancelButtonText: i18n.t("message.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: i18n.t("message.delete_success"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("message.delete_calcel"),
          });
        });
    },
  },
};
</script>
<style lang="scss">
.vuee {
  color: #fff;
  border: 1px solid #2475a8;
  background: #2475a8;
  display: inline-block;
  padding: 0px 5px;
  border-radius: 4px;
  cursor: pointer;
}
</style>